import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { IEnvironment } from 'src/environments/environment.model';

import { ServiceRequestPost, ServiceRequestQueueItemDetailAttachmentResponse, ServiceRequestResponse } from 'src/app/modules/core/models/svc.model';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { withSpinner } from 'src/app/modules/shared/extensions/withSpinner.extension';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable({
	providedIn: 'root'
})
export class SvcService extends UnsubscribeOnDestroy {
	private settings!: IEnvironment;

	constructor(private _http: HttpClient,
		settingsService: SettingsService) {
		super();

		settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			this.settings = settings;
		});
	}

	postServiceRequest(serviceRequestData: ServiceRequestPost): Observable<ServiceRequestResponse> {
		const url = `${this.settings?.apiUrl}svc/serviceRequest/serviceRequestQueueItem`;
		return this._http.post<ServiceRequestResponse>(url, serviceRequestData).pipe(
			map((response) => {
				if (!response) {
					throw new Error('Failed to post service request');
				}

				return response;
			})
		);
	}

	getServiceRequests(lotId: number): Observable<ServiceRequestResponse[]> {
		const url = `${this.settings?.apiUrl}svc/lots/${lotId}`;
		return this._http.get<ServiceRequestResponse[]>(url);
	}

	getAttachmentStorageSas(serviceRequestQueueItemDetailId: number, serviceRequestQueueItemDetailAttachmentId: number): Observable<ServiceRequestQueueItemDetailAttachmentResponse> {
		const url = `${this.settings?.apiUrl}svc/serviceRequestQueueItemDetails/${serviceRequestQueueItemDetailId}/serviceRequestQueueItemDetailAttachments/${serviceRequestQueueItemDetailAttachmentId}/storageSas`;
		return withSpinner(this._http).get<ServiceRequestQueueItemDetailAttachmentResponse>(url);
	}
}